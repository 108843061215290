(function(){function r(e,n,t){function o(i,f){if(!n[i]){if(!e[i]){var c="function"==typeof require&&require;if(!f&&c)return c(i,!0);if(u)return u(i,!0);var a=new Error("Cannot find module '"+i+"'");throw a.code="MODULE_NOT_FOUND",a}var p=n[i]={exports:{}};e[i][0].call(p.exports,function(r){var n=e[i][1][r];return o(n||r)},p,p.exports,r,e,n,t)}return n[i].exports}for(var u="function"==typeof require&&require,i=0;i<t.length;i++)o(t[i]);return o}return r})()({1:[function(require,module,exports){
"use strict";

jQuery(function ($) {
  // Footer menu collapsables
  $(".footer-top").find(".widget").each(function () {
    var widget = $(this);
    widget.children("h5").click(function (e) {
      widget.children("div").stop(true).slideToggle("fast");
      $(this).children("i").toggleClass("fa-chevron-down");
      $(this).children("i").toggleClass("fa-chevron-up");
    });
  }); // Desktop menu icons

  $("#desktop-scouting-menu").on('show.bs.collapse', function (e) {
    $(".scouting-desktop-toggler").children(".btn").addClass("hide");
  });
  $("#desktop-scouting-menu").on('hide.bs.collapse', function (e) {
    $(".scouting-desktop-toggler").children(".btn").removeClass("hide");
  }); // Mobile menu icons

  $("#mobile-menu").on('show.bs.collapse', function (e) {
    if (e.target !== e.currentTarget) return;
    $(".mobile-menu-toggler").children("i").html("close");
    $("#top-nav").find(".buttons").children(".register").hide();
    $("#mobile-menu").children(".buttons").children(".register").show();
  });
  $("#mobile-menu").on('hide.bs.collapse', function (e) {
    if (e.target !== e.currentTarget) return;
    $(".mobile-menu-toggler").children("i").html("menu");
    $("#top-nav").find(".buttons").children(".register").show();
    $("#mobile-menu").children(".buttons").children(".register").hide();
  }); // Scouting Menu Chevron

  $("#mobile-scouting-menu").on('show.bs.collapse', function () {
    $(".scouting-menu-toggler").children("i").removeClass("fa-chevron-down");
    $(".scouting-menu-toggler").children("i").addClass("fa-chevron-up");
  });
  $("#mobile-scouting-menu").on('hide.bs.collapse', function () {
    $(".scouting-menu-toggler").children("i").addClass("fa-chevron-down");
    $(".scouting-menu-toggler").children("i").removeClass("fa-chevron-up");
  }); // Anchors

  $('a').click(function () {
    var href = $(this).attr("href");

    if (href.indexOf('#') > -1) {
      var url = href.split('#');
      var target = decodeURI(url[url.length - 1]);

      if (target !== "") {
        $("html, body").animate({
          scrollTop: $("a[name='" + target + "']").offset().top - $("#top-nav").outerHeight() - $("#wpadminbar").outerHeight() - 20
        });
      }
    }
  });
});
/*
// Dependencies
require('../vendor/polyfill.min.js');

// React
var React = require('react');
var ReactDOM = require('react-dom');

class App extends React.Component {
  render() {
    return (
      <div>
        <h1>Hola mundo</h1>
      </div>
    );
  }
}

const domContainer = document.querySelector('#App');
ReactDOM.render(<App />, domContainer);
*/

},{}]},{},[1]);
